import * as React from 'react';
import { useAuthStore, selectIdName} from "@nike/aegis-auth-react";

function LeaderHome() {

    const user = useAuthStore(selectIdName);

    return (
    <div className="iframe-container">
    <iframe
        src={`https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/d95d588e-0bea-4955-bac6-5e1e5ef647e5#p.leadername=${user}`}
        title="Embedded Dashboard"
    ></iframe>
    </div>
    ); 
}
export default LeaderHome;
