import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { LoginRequired, useAuthStore, selectIsLoggedIn } from "@nike/aegis-auth-react";
import client from "./auth/client.js";
import "./App.css";
import "@nike/eds/dist/index.css";
import Footer from "./Components/Footer.js";
import Header from "./Components/Header.js";
import Navigation from "./Components/Navigation.js";
import Home from "./Pages/Home.js";
import Compute from "./Pages/Compute.js";
import LeaderHome from "./Pages/LeaderHome.js";
import LeaderCompute from "./Pages/LeaderCompute.js";
import Databricks from "./Pages/Databricks.js";


  function App() {
    const NotFound = () => {
      return (
        <>
          <h1>404 - Page Not Found</h1>
        </>
      );
    };

    const isAuthenticated = useAuthStore(selectIsLoggedIn);

    return (
      <LoginRequired client={client}>
        <Router>
        <div className="app">
          {isAuthenticated && <Header />}
            <div className="content">
            {isAuthenticated && <Navigation />}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/compute" element={<Compute />} />
                <Route path="/leader/compute" element={<LeaderCompute/>} />
                <Route path="/leader/home" element={<LeaderHome/>} />
                <Route path="/databricks" element={<Databricks />} />
                <Route path="*" element={<NotFound />}/>
              </Routes>
              {isAuthenticated && <Footer />}
            </div>
          </div>
        </Router>
      </LoginRequired>
    );
  }

  export default App;


