import * as React from 'react';
import { useAuthStore, selectUsername} from "@nike/aegis-auth-react";

function Compute() {
  
  const user = useAuthStore(selectUsername).toLowerCase();
    
  return (
      <div className="iframe-container">
        <iframe
          src={`https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/b0128b9f-990d-4d82-84e2-1e0595bea5fb#p.nikeowner=${user}`}
          title="Embedded Dashboard"
        ></iframe>

      </div>
    ); 
}
export default Compute;
