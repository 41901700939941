import * as React from 'react';
import { useAuthStore, selectIdName} from "@nike/aegis-auth-react";

function LeaderCompute() {
  
  const user = useAuthStore(selectIdName);

  return (
    <div className="iframe-container">
    <iframe
        src={`https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/7297fec5-5f87-4468-b85e-5b8d71cfe084#p.leadername=${user}`}
        title="Embedded Dashboard"
    ></iframe>
  </div>
    ); 
}
export default LeaderCompute;

