import * as React from 'react';
import { useAuthStore, selectUsername} from "@nike/aegis-auth-react";


function Home() {

  const user = useAuthStore(selectUsername).toLowerCase();
    
  return (
    <div className="iframe-container">
    <iframe
        src={`https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/63014c6e-fe14-4c72-a658-529090e9c832#p.nikeowner=${user}`}
        title="Embedded Dashboard"
    ></iframe>
  </div>
    ); 
}
export default Home;
