
import React from 'react';
import { AppHeader, Text, ButtonGroup, Toggle} from "@nike/eds";
import { useAuthStore, selectIdName } from "@nike/aegis-auth-react";
// import SavedView from './SavedViewModal.js';
/* import PinnedOpportunityModal from './PinnedOppModal.js'; */
import "@nike/eds/dist/index.css";
import{useNavigate, useLocation} from 'react-router-dom';


function Header() {

    const user = useAuthStore(selectIdName).split(" ")[0];
    const [isChecked, setIsChecked] = React.useState(false);
    const navigate =useNavigate();
    const location = useLocation();

    React.useEffect(() => {
      if (location.pathname === '/leader/home' || location.pathname === '/leader/compute') {
          setIsChecked(true);
      } else {
          setIsChecked(false);
      }
  }, [location.pathname]);

    const handleLeaderClick = () => {

        setIsChecked(!isChecked);
        if (!isChecked) {
          // When toggling ON, navigate to leader paths
          if (location.pathname === '/compute') {
              navigate('/leader/compute');
          } else if (location.pathname === '/') {
              navigate('/leader/home');
          }
      } else {
          // When toggling OFF, navigate to root and compute paths
          if (location.pathname === '/leader/compute') {
              navigate('/compute');
          } else if (location.pathname === '/leader/home') {
              navigate('/');
          }
      }
    }


    const ToolbarPlaceHolder = ({ isDark, count }) => (
           <ButtonGroup>
           <Text font={"display-5"} as={"p"}>Welcome {user}!</Text>
           <span> &nbsp; </span>
              {/* <SavedView/> */}
              {/* <PinnedOpportunityModal/> */}
              <Toggle id="workday" label="Leadership View" onClick={handleLeaderClick} checked={isChecked}/>
              {/* <Button variant="primary"  onClick={handleRAVClick} active={rav} title="RAV" size= "small">Resource Account View</Button> */}
          </ButtonGroup>

      );


      
      return(
    <div>
      <AppHeader
        appName="Nike Cloud Dashboard Hub"
        toolbarSlot={<ToolbarPlaceHolder isDark />}
        color={"black"}
      />


    </div>
    
    );
}

export default Header;

