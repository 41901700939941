import * as React from 'react';


function Databricks() {
    
return (
    <div className="iframe-container">
      <iframe
        src="https://nike-sole-react.cloud.databricks.com/embed/dashboardsv3/01f008d2babd1777b85b26e2f122f6c3?o=554179923494093"
        title="Embedded Dashboard"
      ></iframe>

    </div>
  ); 
}
export default Databricks;

